<template>

  <n-grid x-gap="12" y-gap="30" :cols="3" v-if="accountinfo_global.username">
    <n-grid-item :offset="1">


      <n-space justify="space-between">
     <n-image
    width="100"
    src="https://assets.dtc.beer/assets/DTC_LOGO_light.png"
  />
     <h1>Customer Cohort creator v0.1 </h1></n-space>


    </n-grid-item>
    <n-grid-item :offset="1">  
        <n-text type="info"  v-if="!success.length">Hello {{ accountinfo_global.name }}, Please fill the form in order to calculate a new cohort : {{ posts.business_name }}</n-text>
    </n-grid-item>
    <n-grid-item :offset="1"> 
        <form  @submit="checkForm" method="post">

            <n-space vertical :size="12">
              <n-alert  v-if="success.length"  title="Success Text" type="success">
                Thank you for the form submission ! You will receive an email notification once the request has been handled, this usually takes from 5 to 30 minutes.
              </n-alert>
              <n-alert  v-if="errors.length"  title="Error Text" type="error"> Please check if the information in the form is correct</n-alert>
            </n-space>

            <n-space vertical   v-if="!success.length">
              <n-space justify="start"><n-text type="warning" >Choose your date range : </n-text></n-space>
              <n-date-picker v-model:value="range" type="daterange" clearable />
              <n-grid x-gap="12" :cols="2">
                <n-gi><n-select v-model:value="posts.market_country" :options="market_options" placeholder="Choose the market" /></n-gi>
                <n-gi><n-select v-model:value="posts.business_name" :options="business_name_options" placeholder="Choose the business" /></n-gi>
              </n-grid>
              <n-select v-model:value="posts.business_categories"  multiple :options="business_category_options_filtered" placeholder="Choose the categories" />
              <n-input v-model:value="accountinfo_global.username" :default-value="accountinfo_global.name" type="text" />
              <n-button type="primary" attr-type="submit" size="large">Submit your request</n-button>
            </n-space>
        </form>
    </n-grid-item>
  </n-grid>


</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
const axios = require('axios');
const msal = require("@azure/msal-browser");


const msalConfig = {
    auth: {
        clientId: '7e30f02a-adf6-4801-90ce-76ee441c4ef8'
    }
};

var loginRequest = {
    scopes: ["user.read", "mail.send"] // optional Array<string>
};


/* add https://vee-validate.logaretm.com/v4/ */

export default defineComponent({
  name: 'RequestForm',
  setup: () => {


  /* move this to root, and then include to src/repository/user/index.js, then const login= */
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    const EventType = ''

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    }, error=>{
      console.log('error', error);
    });


    const loginUser = async () => {
            // handle auth redired/do all initial setup for msal
          let accountinfo = await msalInstance.handleRedirectPromise().then(authResult=>{
              // Check if user signed in 
              console.log(authResult);
              const account = msalInstance.getActiveAccount();

              if(!account){
                // redirect anonymous user to login page 
                msalInstance.loginRedirect(loginRequest);
              }else{
                return account
              }
            }).catch(err=>{
              // TODO: Handle errors
              console.log(err);
            });
            return accountinfo
    }

    const business_name_options = ref([]);
    const business_category_options = ref([]);
    const business_category_options_filtered = ref([]);
    const accountinfo_global = ref([]);

    const downloadJson = async (url) => {
        let res = await axios.get(url, { params: { answer: 42 } });
        //the following is required only because the initial content from adf is not clean json
        var clean_json = res.data.replace(/[\r\n]+/g,",").replace(",{}","");
        clean_json = '['+clean_json.slice(0, -1)+']'
        clean_json = clean_json.replace(',]',']')
        clean_json = JSON.parse(clean_json);
        return clean_json
    };
    const getRemoteData = () => {
      downloadJson('https://dtcecommerceestoresprod.blob.core.windows.net/datascience/public/business_names.json').then((res) => {
        business_name_options.value = res
      })
      downloadJson('https://dtcecommerceestoresprod.blob.core.windows.net/datascience/public/business_categories.json').then((res) => {
        business_category_options.value = res
        console.log('abc',business_category_options.value)
      })
      loginUser().then((accountinfo)=>{
        accountinfo_global.value = accountinfo
       
      })
    }

    onMounted(() => {
      getRemoteData()
    })





    return {
      business_name_options,business_category_options,accountinfo_global,business_category_options_filtered
    }

  },  
  data(){

    console.log('8-8-',this.accountinfo_global);

    return{
        errors: [],
        success: [],
        market_options: [
        {
          label: "FR",
          value: 'FR'
        },{
          label: "BE",
          value: 'BE'
        },
        {
          label: "UK",
          value: 'UK'
        }
      ],
        business_options: [
        {
          label: "beer_cans",
          value: 'beer_cans'
        },{
          label: "bottles",
          value: 'bottles'
        }
      ],
        range: ref([1553135260000, Date.now()]),
        posts:{
            start_date:null,
            end_date:null,
            market_country:null,
            business_name:null,
            business_categories:null,
            email_recipients:this.accountinfo_global.email
        }
    }
  },
  watch:{
    "posts.business_name":{
      handler(){
        this.businessNameChange();
      }
    }
  },  
  methods:{
     businessNameChange: function () {
       this.business_category_options_filtered = this.business_category_options.filter(business_category_option => business_category_option.label.includes(this.posts.business_name.toLowerCase()));
     },
     checkForm: function (e) {
      this.errors = [];

      if (!this.range) {
        this.errors.push("range required.");
      }

      if (!this.posts.business_categories) {
        this.errors.push("category required.");
      }

      if (!this.posts.business_name) {
        this.errors.push("business name required.");
      }
      if (!this.posts.email_recipients) {
        this.errors['email']=1;
      } else if (!this.validEmail(this.posts.email_recipients)) {
        this.errors['email']=1;
      }
      console.warn(this.errors)
      if (!this.errors.length) {
        e.preventDefault();
        return this.postData(e)
      }
      e.preventDefault();

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    postData(e){
        console.warn(this.posts)
        var start_date = new Date(this.range[0]);
        var end_date = new Date(this.range[1] );
        this.posts.start_date = start_date.toISOString().split('T')[0]
        this.posts.end_date = end_date.toISOString().split('T')[0]
        this.posts.email_recipients = this.accountinfo_global.username
        console.warn(this.posts)
        this.axios.post('https://prod-156.westeurope.logic.azure.com:443/workflows/a53ef15526ce4a8ca74cb6e0d87bff28/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lZBjBU8CGRzxrCJBNgF5QmOtl0tNp0mQCIbaJYsRagg',this.posts)
        .then((result)=>{
            console.warn(result)
            if(result.data.success == "True"){
              this.success.push('success');
            }else{
              this.errors['backend_error']=1;
            }
        })
        e.preventDefault();
    }
  }
})
</script>